import React from 'react';

const FullHtmlComponent = (props) => {
  const { data } = props;

  return (
    <div style={{ display: 'content' }} dangerouslySetInnerHTML={{ __html: data.html }}></div>
  );
}

export default FullHtmlComponent;